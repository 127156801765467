// home.js

import React, { useEffect, useRef } from "react";
import "../App.scss";
import bgImage from "../assets/images/heroBg2.png";

function Hero2() {
  const titleRef = useRef(null);

  useEffect(() => {
    const title = titleRef.current;
    title.classList.add('animate-in');
  }, []);

  return (
    <div className="hero-container">
        <div className="overlay"></div>
        <div className="animated-shapes">
          <div className="shape shape-1"></div>
          <div className="shape shape-2"></div>
          <div className="shape shape-3"></div>
        </div>
        
        <div className="content-wrapper" ref={titleRef}>
          <div className="title">
            <div className="title-inner">
              <div className="highlight-bar"></div>
              <div className="cafe">
                <div className="cafe-inner">
                  <span className="text-gradient">KJ GROUP</span>
                </div>
              </div>

              <div className="mozart">
                <div className="mozart-inner">
<div>

                  Vitality through 
                  <span className="highlight"> Cuisine, </span> 
</div>

                   Unity through
                  <span className="highlight"> Culture </span>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Hero2;
