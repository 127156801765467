import React from 'react';
import { Link, Box, Typography, IconButton } from '@mui/material';
import Phone from '@mui/icons-material/Phone';
import Language from '@mui/icons-material/Language';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Temp1 from '../assets/images/client3.jpg';
import Temp2 from '../assets/images/client1.jpg';
import Temp3 from '../assets/images/client2.jpg';
import Temp4 from '../assets/images/client4.png';
import { Fade } from "react-awesome-reveal";

function Cards() {
    const restaurants = [
        {
            name: "Korean BBQ Edinburgh",
            image: Temp1,
            phone: "+44 131 229 6789",
            description: "Where small and shared plates take center stage. Immerse yourself in the art of Korean BBQ with our built-in table grills.",
            website: "https://koreanbbq.kjgroups.co.uk",
        },
        {
            name: "Korean BBQ Dundee",
            image: Temp4,
            phone: "+44 138 252 1144",
            description: "Experience authentic Korean BBQ in Dundee. Premium meats, traditional sides, and interactive dining experience.",
            website: "https://koreanbbqdundee.kjgroups.co.uk/",
        },
        {
            name: "Korean Munchies",
            image: Temp2,
            phone: "+44 131 285 3038",
            description: "The first Korean Street Food Space in the city, offering an easy-going environment for authentic Korean delights.",
            website: "https://koreanmunchies.kjgroups.co.uk/",
        },
        {
            name: "Soul Sushi",
            image: Temp3,
            phone: "+44 131 667 0200",
            description: "Fresh rolls, nigiri, sashimi, poké bowls & hot katsu curries with great student deals and amazing atmosphere.",
            website: "https://soulsushi.kjgroups.co.uk/",
        },
        
    ];

    const handleTouch = (event) => {
        const cardInner = event.currentTarget;
        cardInner.classList.toggle('touched');
    };

    return (
        <Box className="restaurants-section">
            <Fade cascade damping={0.2} direction="down">
                <Typography variant="h1" className="section-title">
                    Our Restaurants
                </Typography>
                <Typography variant="subtitle1" className="section-subtitle">
                    Authentic Asian Cuisine in Scotland
                </Typography>
            </Fade>
            
            <Box className="cards-container">
                {restaurants.map((restaurant, index) => (
                    <Fade 
                        key={index}
                        duration={1500} 
                        triggerOnce={true} 
                        direction={
                            index === 0 ? "left" : 
                            index === 1 ? "up" : 
                            index === 2 ? "down" : "right"
                        }
                    >
                        <Box className="restaurant-card">
                            <Box 
                                className="card-inner"
                                onClick={handleTouch}
                                sx={{ cursor: 'pointer' }}
                            >
                                <Box className="card-front">
                                    <Box className="image-wrapper">
                                        <img src={restaurant.image} alt={restaurant.name} />
                                    </Box>
                                    <Box className="card-content">
                                        <Typography variant="h2" className="restaurant-name">
                                            {restaurant.name}
                                        </Typography>
                                        <Typography className="description">
                                            {restaurant.description}
                                        </Typography>
                                        <Box className="flip-hint">
                                            <Typography variant="caption">
                                                Click to view contact
                                            </Typography>
                                            <ArrowForward sx={{ fontSize: 16 }} />
                                        </Box>
                                    </Box>
                                </Box>
                                
                                <Box className="card-back">
                                    <Box className="contact-wrapper">
                                        <Typography variant="h3" className="contact-title">
                                            Get in Touch
                                        </Typography>
                                        <Link 
                                            href={`tel:${restaurant.phone}`} 
                                            className="contact-button phone-button"
                                        >
                                            <Phone /> Call Now
                                        </Link>
                                        <Link 
                                            href={restaurant.website}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="contact-button website-button"
                                        >
                                            <Language /> Visit Website
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Fade>
                ))}
            </Box>
        </Box>
    );
}

export default Cards;
