import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Typography, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import InstagramIcon from '@mui/icons-material/Instagram';
import Hamburger from "./Hamburger";

const Header = () => {
  const location = useLocation();
  const [menuState, setMenuState] = useState({
    clicked: false,
    menuName: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    setMenuState({
      clicked: false,
      menuName: "Menu",
    });
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleMenu = () => {
    disableMenu();
    setMenuState((prevState) => ({
      clicked: !prevState.clicked,
      menuName: prevState.clicked ? "Menu" : "Close",
    }));
  };

  const disableMenu = () => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 1200);
  };

  return (
    <Box
      component="header"
      sx={{
        background: scrolled 
          ? 'rgba(20, 20, 20, 0.95)'
          : 'transparent',
        color: "#fff",
        position: 'fixed',
        width: '100%',
        top: 0,
        zIndex: 1000,
        transition: 'all 0.4s ease-in-out',
        backdropFilter: scrolled ? 'blur(15px)' : 'none',
        boxShadow: scrolled ? '0 4px 30px rgba(0, 0, 0, 0.1)' : 'none',
        borderBottom: scrolled ? '1px solid rgba(255, 255, 255, 0.1)' : 'none',
      }}
    >
      <Box
        padding="20px 30px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          maxWidth: '1400px',
          margin: '0 auto',
        }}
      >
        {/* Logo */}
        <Typography 
          variant="h6" 
          sx={{ 
            textShadow: "0 2px 10px rgba(255, 255, 255, 0.3)",
            transform: 'translateX(0)',
            transition: 'all 0.3s ease',
            position: 'relative',
            '&:hover': {
              transform: 'translateX(5px)',
              '&::after': {
                width: '100%',
              }
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-5px',
              left: '0',
              width: '0',
              height: '2px',
              background: 'linear-gradient(90deg, #FF4D4D 0%, #F9CB28 100%)',
              transition: 'width 0.3s ease',
            }
          }}
        >
          <Link to="/" style={{
            color: "#fff", 
            textDecoration: "none", 
            fontWeight: '700',
            fontSize: '1.8rem',
            letterSpacing: '2px',
            background: 'linear-gradient(90deg, #FF4D4D, #F9CB28)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}>
            KJ GROUP
          </Link>
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          {/* Instagram Icon */}
          <IconButton
            component="a"
            href="https://www.instagram.com/kjgroup.uk"
            target="_blank"
            sx={{
              color: "#fff",
              transition: 'all 0.3s ease',
              background: 'linear-gradient(135deg, rgba(255, 77, 77, 0.1) 0%, rgba(249, 203, 40, 0.1) 100%)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              backdropFilter: 'blur(5px)',
              '&:hover': {
                transform: 'scale(1.1)',
                background: 'linear-gradient(135deg, rgba(255, 77, 77, 0.2) 0%, rgba(249, 203, 40, 0.2) 100%)',
              }
            }}
          >
            <InstagramIcon sx={{ fontSize: 24 }} />
          </IconButton>

          {/* Menu Button */}
          <IconButton
            sx={{
              color: "#fff",
              transition: 'all 0.3s ease',
              background: 'linear-gradient(135deg, rgba(255, 77, 77, 0.1) 0%, rgba(249, 203, 40, 0.1) 100%)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              backdropFilter: 'blur(5px)',
              '&:hover': {
                transform: 'rotate(180deg) scale(1.1)',
                background: 'linear-gradient(135deg, rgba(255, 77, 77, 0.2) 0%, rgba(249, 203, 40, 0.2) 100%)',
              }
            }}
            onClick={handleMenu}
            disabled={disabled}
          >
            {menuState.clicked ? (
              <CloseIcon sx={{ fontSize: 28 }} />
            ) : (
              <MenuIcon sx={{ fontSize: 28 }} />
            )}
          </IconButton>
        </Box>
      </Box>

      <Hamburger state={menuState} handleMenu={handleMenu} />
    </Box>
  );
};

export default Header;